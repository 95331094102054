import { of, Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { SignalEntityEvent, SignalEntityType, SignalRService } from "./signal-r.service";
import { OilHandbookApiClientService } from "oil-handbook-api";
import { StorageEnum, StorageService, UserService } from "oil-auth";
import { PermissionLevel } from "../models/types/employee-access-settings.enum";
import { HandbookPermissionKeysEnum } from "../models/types/handbook-permission-keys.enum";
import { OilAuthApiClientService } from "oil-auth-api";
import * as i0 from "@angular/core";
import * as i1 from "./signal-r.service";
import * as i2 from "oil-handbook-api";
import * as i3 from "oil-auth";
import * as i4 from "oil-auth-api";
export class ChangesPipelineService {
    constructor(signalRService, api, userService, apiClientService, storageService) {
        this.signalRService = signalRService;
        this.api = api;
        this.userService = userService;
        this.apiClientService = apiClientService;
        this.storageService = storageService;
        this.processEmployee = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.employees.get(container.id)
                        .subscribe(data => {
                        this.fire(container, data);
                    });
                    break;
            }
        };
        this.processCatalogGroup = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.catalog.groups.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processProduct = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.catalog.products.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processClients = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.clients.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processClientType = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.clients.types.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCarrier = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processSupplier = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.suppliers.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processProductRules = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.suppliers.productRules.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processLegalEntities = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.legalEntities.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processAccounts = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.legalEntities.accounts.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processClientAddress = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.clients.addresses.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processBase = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.bases.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processBasisGroup = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.bases.groups.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processDriver = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.drivers.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCarAxle = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.cars.axles.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCarTractor = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.cars.tractors.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCarTrailer = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.cars.trailers.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCarTrailerSection = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.cars.trailers.sections.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processCar = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.carriers.cars.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processLegalEntityLinkToOwn = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.legalEntities.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processDepot = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.depots.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.processTank = (container) => {
            switch (container.event) {
                case SignalEntityEvent.Removed:
                    this.fire(container);
                    break;
                case SignalEntityEvent.Created:
                case SignalEntityEvent.Updated:
                case SignalEntityEvent.Archived:
                    this.api.depots.tanks.get(container.id)
                        .subscribe(data => this.fire(container, data));
                    break;
            }
        };
        this.pipeline = new Subject();
    }
    init() {
        this.signalSubscription = this.signalRService.onReceive.subscribe((model) => {
            this.process(model);
        });
    }
    permissionByKey(key, container, callback) {
        const permission = this.userService.checkPermission(key, PermissionLevel.Restricted);
        if (!permission) {
            callback(container);
        }
    }
    processEmployeeWithData(key, container) {
        this.getUserNewData(container)
            .subscribe(() => this.permissionByKey(key, container, this.processEmployee));
    }
    getUserNewData(container) {
        if (this.userService.userData && this.userService.userData.user &&
            container.id === this.userService.userData.user.employee_id) {
            const token = this.storageService.getValue(StorageEnum.token);
            const key = token && token.access && token.access.token || '';
            return this.apiClientService.tokens.self(key)
                .pipe(tap((response) => {
                this.userService.setUserData(response);
            }));
        }
        return of(null);
    }
    process(container) {
        console.log('container', container);
        switch (container.type) {
            case SignalEntityType.Employee:
                this.processEmployeeWithData(HandbookPermissionKeysEnum.EMPLOYEE, container);
                break;
            case SignalEntityType.CatalogGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGGROUPS, container, this.processCatalogGroup);
                break;
            case SignalEntityType.Product:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGPRODUCTS, container, this.processProduct);
                break;
            case SignalEntityType.ClientType:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSTYPES, container, this.processClientType);
                break;
            case SignalEntityType.Client:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTS, container, this.processClients);
                break;
            case SignalEntityType.Carrier:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERS, container, this.processCarrier);
                break;
            case SignalEntityType.Supplier:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERS, container, this.processSupplier);
                break;
            case SignalEntityType.ProductRule:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERSPRODUCTRULES, container, this.processProductRules);
                break;
            case SignalEntityType.LegalEntity:
                this.permissionByKey(HandbookPermissionKeysEnum.LEGALENTITIES, container, this.processLegalEntities);
                break;
            case SignalEntityType.SettlementAccount:
                this.permissionByKey(HandbookPermissionKeysEnum.ACCOUNTS, container, this.processAccounts);
                break;
            case SignalEntityType.ClientAddress:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSADDRESSES, container, this.processClientAddress);
                break;
            case SignalEntityType.Basis:
                this.permissionByKey(HandbookPermissionKeysEnum.BASES, container, this.processBase);
                break;
            case SignalEntityType.BasisGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.BASESGROUPS, container, this.processBasisGroup);
                break;
            case SignalEntityType.Driver:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSDRIVERS, container, this.processDriver);
                break;
            case SignalEntityType.CarAxle:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARSAXLES, container, this.processCarAxle);
                break;
            case SignalEntityType.CarTractor:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRACTORS, container, this.processCarTractor);
                break;
            case SignalEntityType.CarTrailer:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERS, container, this.processCarTrailer);
                break;
            case SignalEntityType.CarTrailerSection:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERSSECTIONS, container, this.processCarTrailerSection);
                this.processCarTrailerSection(container);
                break;
            case SignalEntityType.Car:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARS, container, this.processCar);
                break;
            case SignalEntityType.LegalEntityLinkToOwn:
                this.permissionByKey(HandbookPermissionKeysEnum.OWNLEGALENTITIES, container, this.processLegalEntityLinkToOwn);
                break;
            case SignalEntityType.Depot:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processDepot);
                break;
            case SignalEntityType.Tank:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processTank);
                break;
        }
    }
    fire(container, data = null) {
        this.pipeline.next({
            message: container,
            data: data
        });
    }
    onEmployeeAdd() {
        return this.subscribe([], [SignalEntityType.Employee], [SignalEntityEvent.Created]);
    }
    onEmployeeUpdate() {
        return this.subscribe([], [SignalEntityType.Employee], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onEmployeeUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Employee], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCatalogGroupAdd() {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Created]);
    }
    onCatalogGroupUpdate() {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCatalogGroupUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCatalogGroupDelete() {
        return this.subscribe([], [SignalEntityType.CatalogGroup], [SignalEntityEvent.Removed]);
    }
    onProductAdd() {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Created]);
    }
    onProductUpdate() {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onProductUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Product], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onProductDelete() {
        return this.subscribe([], [SignalEntityType.Product], [SignalEntityEvent.Removed]);
    }
    onClientTypeAdd() {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Created]);
    }
    onClientTypeUpdate() {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientTypeUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ClientType], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientTypeDelete() {
        return this.subscribe([], [SignalEntityType.ClientType], [SignalEntityEvent.Removed]);
    }
    onClientAdd() {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Created]);
    }
    onClientUpdate() {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Client], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientDelete() {
        return this.subscribe([], [SignalEntityType.Client], [SignalEntityEvent.Removed]);
    }
    onCarrierAdd() {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Created]);
    }
    onCarrierUpdate() {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarrierUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Carrier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarrierDelete() {
        return this.subscribe([], [SignalEntityType.Carrier], [SignalEntityEvent.Removed]);
    }
    onCarTractorAdd() {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Created]);
    }
    onCarTractorUpdate() {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarTractorUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTractor], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarTractorDelete() {
        return this.subscribe([], [SignalEntityType.CarTractor], [SignalEntityEvent.Removed]);
    }
    onCarAdd() {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Created]);
    }
    onCarUpdate() {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Car], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarDelete() {
        return this.subscribe([], [SignalEntityType.Car], [SignalEntityEvent.Removed]);
    }
    onCarTrailerAdd() {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Created]);
    }
    onCarTrailerUpdate() {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarTrailerUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTrailer], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarTrailerDelete() {
        return this.subscribe([], [SignalEntityType.CarTrailer], [SignalEntityEvent.Removed]);
    }
    onSupplierAdd() {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Created]);
    }
    onSupplierUpdate() {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onSupplierUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Supplier], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onSupplierDelete() {
        return this.subscribe([], [SignalEntityType.Supplier], [SignalEntityEvent.Removed]);
    }
    onProductRuleAdd() {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Created]);
    }
    onProductRuleUpdate() {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onProductRuleUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ProductRule], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onProductRuleDelete() {
        return this.subscribe([], [SignalEntityType.ProductRule], [SignalEntityEvent.Removed]);
    }
    onLegalEntityAdd() {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Created]);
    }
    onLegalEntityLinkToOwnAdd() {
        return this.subscribe([], [SignalEntityType.LegalEntityLinkToOwn], [SignalEntityEvent.Created]);
    }
    onLegalEntityUpdate() {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onLegalEntityUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.LegalEntity], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onLegalEntityDelete() {
        return this.subscribe([], [SignalEntityType.LegalEntity], [SignalEntityEvent.Removed]);
    }
    onSettlementAccountAdd() {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Created]);
    }
    onSettlementAccountUpdate() {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onSettlementAccountUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onSettlementAccountDelete() {
        return this.subscribe([], [SignalEntityType.SettlementAccount], [SignalEntityEvent.Removed]);
    }
    onClientAddressAdd() {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Created]);
    }
    onClientAddressUpdate() {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientAddressUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.ClientAddress], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onClientAddressDelete() {
        return this.subscribe([], [SignalEntityType.ClientAddress], [SignalEntityEvent.Removed]);
    }
    onBasisAdd() {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Created]);
    }
    onBasisUpdate() {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onBasisUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Basis], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onBasisDelete() {
        return this.subscribe([], [SignalEntityType.Basis], [SignalEntityEvent.Removed]);
    }
    onBasisGroupAdd() {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Created]);
    }
    onBasisGroupUpdate() {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onBasisGroupUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.BasisGroup], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onBasisGroupDelete() {
        return this.subscribe([], [SignalEntityType.BasisGroup], [SignalEntityEvent.Removed]);
    }
    onDriverAdd() {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Created]);
    }
    onDriverUpdate() {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onDriverUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Driver], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onDriverDelete() {
        return this.subscribe([], [SignalEntityType.Driver], [SignalEntityEvent.Removed]);
    }
    onCarAxleAdd() {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Created]);
    }
    onCarAxleUpdate() {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarAxleUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarAxle], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCarAxleDelete() {
        return this.subscribe([], [SignalEntityType.CarAxle], [SignalEntityEvent.Removed]);
    }
    onTrailerSectionAdd() {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Created]);
    }
    onTrailerSectionUpdate() {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onCTrailerSectionUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onTrailerSectionDelete() {
        return this.subscribe([], [SignalEntityType.CarTrailerSection], [SignalEntityEvent.Removed]);
    }
    onTankAdd() {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Created]);
    }
    onDepotAdd() {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Created]);
    }
    onDepotUpdate() {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onDepotUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Depot], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onDepotDelete() {
        return this.subscribe([], [SignalEntityType.Depot], [SignalEntityEvent.Removed]);
    }
    onTankUpdate() {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onTankUpdateById(loadingId) {
        return this.subscribe([loadingId], [SignalEntityType.Tank], [SignalEntityEvent.Updated, SignalEntityEvent.Archived]);
    }
    onTankDelete() {
        return this.subscribe([], [SignalEntityType.Tank], [SignalEntityEvent.Removed]);
    }
    subscribe(ids, types, events) {
        return this.pipeline.pipe(filter(container => {
            if (0 != ids.length && !ids.includes(container.message.id)) {
                return false;
            }
            if (0 != types.length && !types.includes(container.message.type)) {
                return false;
            }
            if (0 != events.length && !events.includes(container.message.event)) {
                return false;
            }
            return true;
        }));
    }
    destroy() {
        this.signalSubscription.unsubscribe();
    }
}
ChangesPipelineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangesPipelineService_Factory() { return new ChangesPipelineService(i0.ɵɵinject(i1.SignalRService), i0.ɵɵinject(i2.OilHandbookApiClientService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.OilAuthApiClientService), i0.ɵɵinject(i3.StorageService)); }, token: ChangesPipelineService, providedIn: "root" });
