import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
export class SignalRService {
    constructor() {
        this.pipeline = new Subject();
        this.buildConnection();
    }
    get onReceive() {
        return this.pipeline;
    }
    buildConnection() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.baseUrl}/hubs/notifications`)
            .withAutomaticReconnect([5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000])
            .build();
        this.restart(hubConnection);
        hubConnection.onclose(() => {
            this.restart(hubConnection);
        });
    }
    restart(connection) {
        connection.start().then(() => {
            connection.on("Entity", (data) => {
                this.pipeline.next(data);
            });
        });
    }
}
SignalRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignalRService_Factory() { return new SignalRService(); }, token: SignalRService, providedIn: "root" });
export var SignalEntityEvent;
(function (SignalEntityEvent) {
    SignalEntityEvent["Created"] = "Created";
    SignalEntityEvent["Updated"] = "Updated";
    SignalEntityEvent["Removed"] = "Removed";
    SignalEntityEvent["Reorder"] = "Reorder";
    SignalEntityEvent["Archived"] = "Archived";
})(SignalEntityEvent || (SignalEntityEvent = {}));
export var SignalEntityType;
(function (SignalEntityType) {
    SignalEntityType["Employee"] = "Employee";
    SignalEntityType["CatalogGroup"] = "CatalogGroup";
    SignalEntityType["Product"] = "Product";
    SignalEntityType["ClientType"] = "ClientType";
    SignalEntityType["Client"] = "Client";
    SignalEntityType["Carrier"] = "Carrier";
    SignalEntityType["Supplier"] = "Supplier";
    SignalEntityType["ProductRule"] = "ProductRule";
    SignalEntityType["LegalEntity"] = "LegalEntity";
    SignalEntityType["SettlementAccount"] = "SettlementAccount";
    SignalEntityType["ClientAddress"] = "ClientAddress";
    SignalEntityType["Basis"] = "Basis";
    SignalEntityType["BasisGroup"] = "BasisGroup";
    SignalEntityType["Driver"] = "Driver";
    SignalEntityType["CarSection"] = "CarSection";
    SignalEntityType["CarAxle"] = "CarAxle";
    SignalEntityType["CarTractor"] = "CarTractor";
    SignalEntityType["CarTrailerSection"] = "CarTrailerSection";
    SignalEntityType["Car"] = "Car";
    SignalEntityType["CarTrailer"] = "CarTrailer";
    SignalEntityType["LegalEntityLinkToOwn"] = "LegalEntityLinkToOwn";
    SignalEntityType["Depot"] = "Depot";
    SignalEntityType["Tank"] = "Tank";
})(SignalEntityType || (SignalEntityType = {}));
