import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {AuthComponent, LogoutComponent, PermissionGuard, TokenComponent} from 'oil-auth';
import {HandbookPermissionKeysEnum} from "./models/types/handbook-permission-keys.enum";

const routes: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    data: {permission: HandbookPermissionKeysEnum.HANDBOOK},
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'authorization',
    component: AuthComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'token',
    component: TokenComponent,
  },
  {path: '**', redirectTo: ''},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
