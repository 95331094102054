import { Injectable } from "@angular/core"
import { NbIconLibraries } from "@nebular/theme"

@Injectable({
    providedIn: "root"
})
export class IconLoadService {

    public constructor(
        private library: NbIconLibraries
    ) { }

    public load(): void {
        this.library.registerSvgPack('oil', {
            'loading': require('!!raw-loader!../../assets/images/directories/loading.svg'),
            'all-done': require('!!raw-loader!../../assets/images/directories/all-done.svg'),
            'providers': require('!!raw-loader!../../assets/images/directories/providers.svg'),
            'bases': require('!!raw-loader!../../assets/images/directories/bases.svg'),
        });
    }
}

