import { Component, OnDestroy, OnInit } from '@angular/core'
import { ChangesPipelineService } from "./services/changes-pipeline.service"
import { IconLoadService } from "./services/icon-load.service"
import { TokenService } from "oil-auth"

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private changesPipeline: ChangesPipelineService,
    private iconLoader: IconLoadService,
    private tokenService: TokenService
  ) {}

  public ngOnInit() {

    this.iconLoader.load()

    this.changesPipeline.init()
    this.tokenService.startWatch()
  }

  public ngOnDestroy(): void {
    this.changesPipeline.destroy()
    this.tokenService.startWatch()
  }
}
