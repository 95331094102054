export enum EmployeeAccessSettingsEnum {
    PROHIBITEd = 1,
    READING_ALLOWED ,
    WRITING_ALLOWED ,
}

export enum PermissionLevel {
    Restricted = 1,
    Read = 2,
    Write = 3
}