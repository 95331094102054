import { environment } from "../environments/environment";
import { EnvLibraryConfig } from "oil-auth";
const oilAuthConfig = {
    host: environment.authApi,
    login: environment.authUrl
};
const ɵ0 = { host: environment.authApi }, ɵ1 = { host: environment.baseUrl };
export class AppModule {
}
export { ɵ0, ɵ1 };
