import { Injectable } from "@angular/core"
import {Observable, of, Subject, Subscription} from "rxjs"
import {delay, filter, switchMap, tap} from "rxjs/operators"
import { SignalEntityEvent, SignalEntityEventContainer, SignalEntityType, SignalRService } from "./signal-r.service"
import {EmployeeModel} from "oil-handbook-api/lib/parts/employees/employees";
import {OilHandbookApiClientService} from "oil-handbook-api";
import {EntityId} from "oil-handbook-api/lib/api.interface";
import {ProductCatalogGroupModel} from "oil-handbook-api/lib/parts/catalog/catalog";
import {ProductModel} from "oil-handbook-api/lib/parts/catalog/products";
import {ClientTypeModel} from "oil-handbook-api/lib/parts/clients/clients.types";
import {ClientModel} from "oil-handbook-api/lib/parts/clients/clients";
import {CarrierModel} from "oil-handbook-api/lib/parts/carriers/carriers";
import {SupplierModel} from "oil-handbook-api/lib/parts/suppliers/suppliers";
import {ProductRuleModel} from "oil-handbook-api/lib/parts/suppliers/suppliers.product-rules";
import {LegalEntityModel} from "oil-handbook-api/lib/parts/legal-entities/legal-entities";
import {
    SettlementAccountModel
} from "oil-handbook-api/lib/parts/legal-entities/legal-entities.settlement-accounts";
import {ClientAddressModel} from "oil-handbook-api/lib/parts/clients/clients.addresses";
import {BasisModel} from "oil-handbook-api/lib/parts/bases/bases";
import {BasisGroupModel} from "oil-handbook-api/lib/parts/bases/bases.groups";
import {DriverModel} from "oil-handbook-api/lib/parts/carriers/carriers.drivers";
import {CarAxleModel} from "oil-handbook-api/lib/parts/carriers/carriers.cars.axles";
import {CarTractorModel} from "oil-handbook-api/lib/parts/carriers/carriers.cars.tractors";
import {CarTrailerModel} from "oil-handbook-api/lib/parts/carriers/carriers.cars.trailers";
import {TrailerSectionModel} from "oil-handbook-api/lib/parts/carriers/carriers.cars.trailers.sections";
import {StorageEnum, StorageService, UserService} from "oil-auth";
import {PermissionLevel} from "../models/types/employee-access-settings.enum";
import {HandbookPermissionKeysEnum} from "../models/types/handbook-permission-keys.enum";
import {UserDataInterface} from "oil-auth/lib/models/user-data.interface";
import {OilAuthApiClientService} from "oil-auth-api";
import {TokenPairModel} from "oil-auth-api/lib/parts/auth";
import {CarModel} from "oil-handbook-api/lib/parts/carriers/carriers.cars";
import {TankModel} from "oil-handbook-api/lib/parts/depots/depots.tanks";
import {DepotModel} from "oil-handbook-api/lib/parts/depots/depots";

@Injectable({
    providedIn: "root"
})
export class ChangesPipelineService {

    private signalSubscription: Subscription
    private pipeline: Subject<ChangesContainer<any>>

    public constructor(
        private signalRService: SignalRService,
        private api: OilHandbookApiClientService,
        private userService: UserService,
        private apiClientService: OilAuthApiClientService,
        private storageService: StorageService,
    ) {
        this.pipeline = new Subject<ChangesContainer<any>>()
    }

    public init(): void {
        this.signalSubscription = this.signalRService.onReceive.subscribe((model) => {
            this.process(model);
        });
    }

   permissionByKey(key: string, container: SignalEntityEventContainer, callback) {
        const permission = this.userService.checkPermission(key, PermissionLevel.Restricted);
        if (!permission) {
            callback(container);
        }
    }

   processEmployeeWithData(key: string, container: SignalEntityEventContainer) {
        this.getUserNewData(container)
            .subscribe(() => this.permissionByKey(key, container, this.processEmployee));
    }

    getUserNewData(container: SignalEntityEventContainer) {
        if (this.userService.userData && this.userService.userData.user &&
            container.id === this.userService.userData.user.employee_id) {
            const token: TokenPairModel = this.storageService.getValue(StorageEnum.token);
            const key = token && token.access && token.access.token || '' ;
            return this.apiClientService.tokens.self(key)
                .pipe(
                    tap((response) => {
                        this.userService.setUserData(response);
                    })
                );
        }
        return of(null);
    }

    private process(container: SignalEntityEventContainer) {
        console.log('container', container);

        switch (container.type) {
            case SignalEntityType.Employee:
                this.processEmployeeWithData(HandbookPermissionKeysEnum.EMPLOYEE, container);
                break;
            case SignalEntityType.CatalogGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGGROUPS, container, this.processCatalogGroup);
                break;
            case SignalEntityType.Product:
                this.permissionByKey(HandbookPermissionKeysEnum.CATALOGPRODUCTS, container, this.processProduct);
                break;
            case SignalEntityType.ClientType:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSTYPES, container, this.processClientType);
                break;
            case SignalEntityType.Client:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTS, container, this.processClients);
                break;
            case SignalEntityType.Carrier:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERS, container, this.processCarrier);
                break;
            case SignalEntityType.Supplier:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERS, container, this.processSupplier);
                break;
            case SignalEntityType.ProductRule:
                this.permissionByKey(HandbookPermissionKeysEnum.SUPPLIERSPRODUCTRULES, container, this.processProductRules);
                break;
            case SignalEntityType.LegalEntity:
                this.permissionByKey(HandbookPermissionKeysEnum.LEGALENTITIES, container, this.processLegalEntities);
                break;
            case SignalEntityType.SettlementAccount:
                this.permissionByKey(HandbookPermissionKeysEnum.ACCOUNTS, container, this.processAccounts);
                break;
            case SignalEntityType.ClientAddress:
                this.permissionByKey(HandbookPermissionKeysEnum.CLIENTSADDRESSES, container, this.processClientAddress);
                break;
            case SignalEntityType.Basis:
                this.permissionByKey(HandbookPermissionKeysEnum.BASES, container, this.processBase);
                break;
            case SignalEntityType.BasisGroup:
                this.permissionByKey(HandbookPermissionKeysEnum.BASESGROUPS, container, this.processBasisGroup);
                break;
            case SignalEntityType.Driver:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSDRIVERS, container, this.processDriver);
                break;
            case SignalEntityType.CarAxle:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARSAXLES, container, this.processCarAxle);
                break;
            case SignalEntityType.CarTractor:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRACTORS, container, this.processCarTractor);
                break;
            case SignalEntityType.CarTrailer:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERS, container, this.processCarTrailer);
                break;
            case SignalEntityType.CarTrailerSection:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSTRAILERSSECTIONS, container, this.processCarTrailerSection);
                this.processCarTrailerSection(container);
                break;
            case SignalEntityType.Car:
                this.permissionByKey(HandbookPermissionKeysEnum.CARRIERSCARS, container, this.processCar);
                break;
            case SignalEntityType.LegalEntityLinkToOwn:
                this.permissionByKey(HandbookPermissionKeysEnum.OWNLEGALENTITIES, container, this.processLegalEntityLinkToOwn);
                break;
            case SignalEntityType.Depot:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processDepot);
                break;
            case SignalEntityType.Tank:
                this.permissionByKey(HandbookPermissionKeysEnum.HANDBOOKDEPOT, container, this.processTank);
                break;
        }
    }

    private processEmployee = (container: SignalEntityEventContainer) => {

        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.employees.get(container.id)
                    .subscribe(data => {
                        this.fire(container, data);
                    });
                break;
        }
    }
    private processCatalogGroup = (container: SignalEntityEventContainer) =>{

        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.catalog.groups.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processProduct = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.catalog.products.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processClients = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.clients.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processClientType = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.clients.types.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processCarrier = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processSupplier  = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.suppliers.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processProductRules = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.suppliers.productRules.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processLegalEntities = (container: SignalEntityEventContainer) =>{
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.legalEntities.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processAccounts = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.legalEntities.accounts.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processClientAddress = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.clients.addresses.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processBase = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.bases.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processBasisGroup = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.bases.groups.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processDriver = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.drivers.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processCarAxle = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.cars.axles.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processCarTractor = (container: SignalEntityEventContainer) =>{
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.cars.tractors.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processCarTrailer = (container: SignalEntityEventContainer) =>{
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.cars.trailers.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processCarTrailerSection = (container: SignalEntityEventContainer) =>{
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.cars.trailers.sections.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processCar = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.carriers.cars.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processLegalEntityLinkToOwn = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.legalEntities.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

  private processDepot = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.depots.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }
  private processTank = (container: SignalEntityEventContainer) => {
        switch (container.event) {
            case SignalEntityEvent.Removed:
                this.fire(container);
                break;

            case SignalEntityEvent.Created:
            case SignalEntityEvent.Updated:
            case SignalEntityEvent.Archived:
                this.api.depots.tanks.get(container.id)
                    .subscribe(data => this.fire(container, data));
                break;
        }
    }

    private fire<T>(container: SignalEntityEventContainer, data: T = null) {

        this.pipeline.next({
            message: container,
            data: data
        });
    }

    public onEmployeeAdd(): Observable<ChangesContainer<EmployeeModel>> {
        return this.subscribe<EmployeeModel>([], [ SignalEntityType.Employee ], [ SignalEntityEvent.Created ])
    }

    public onEmployeeUpdate(): Observable<ChangesContainer<EmployeeModel>> {
        return this.subscribe<EmployeeModel>([], [ SignalEntityType.Employee ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ])
    }

    public onEmployeeUpdateById(loadingId: EntityId): Observable<ChangesContainer<EmployeeModel>> {
        return this.subscribe<EmployeeModel>([ loadingId ],
            [ SignalEntityType.Employee ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ])
    }

    public onCatalogGroupAdd(): Observable<ChangesContainer<ProductCatalogGroupModel>> {
        return this.subscribe<ProductCatalogGroupModel>([], [ SignalEntityType.CatalogGroup ], [ SignalEntityEvent.Created ])
    }

    public onCatalogGroupUpdate(): Observable<ChangesContainer<ProductCatalogGroupModel>> {
        return this.subscribe<ProductCatalogGroupModel>([],
            [ SignalEntityType.CatalogGroup ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCatalogGroupUpdateById(loadingId: EntityId): Observable<ChangesContainer<ProductCatalogGroupModel>> {
        return this.subscribe<ProductCatalogGroupModel>([ loadingId ],
            [ SignalEntityType.CatalogGroup ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ])
    }

    public onCatalogGroupDelete(): Observable<ChangesContainer<ProductCatalogGroupModel>> {
        return this.subscribe<ProductCatalogGroupModel>([], [ SignalEntityType.CatalogGroup ], [ SignalEntityEvent.Removed ]);
    }

    public onProductAdd(): Observable<ChangesContainer<ProductModel>> {
        return this.subscribe<ProductModel>([],
            [ SignalEntityType.Product ], [ SignalEntityEvent.Created ]);
    }

    public onProductUpdate(): Observable<ChangesContainer<ProductModel>> {
        return this.subscribe<ProductModel>([],
            [ SignalEntityType.Product ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onProductUpdateById(loadingId: EntityId): Observable<ChangesContainer<ProductModel>> {
        return this.subscribe<ProductModel>([ loadingId ],
            [ SignalEntityType.Product ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onProductDelete(): Observable<ChangesContainer<ProductModel>> {
        return this.subscribe<ProductModel>([],
            [ SignalEntityType.Product ], [ SignalEntityEvent.Removed ]);
    }

    public onClientTypeAdd(): Observable<ChangesContainer<ClientTypeModel>> {
        return this.subscribe<ClientTypeModel>([],
            [ SignalEntityType.ClientType ], [ SignalEntityEvent.Created ]);
    }

    public onClientTypeUpdate(): Observable<ChangesContainer<ClientTypeModel>> {
        return this.subscribe<ClientTypeModel>([],
            [ SignalEntityType.ClientType ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientTypeUpdateById(loadingId: EntityId): Observable<ChangesContainer<ClientTypeModel>> {
        return this.subscribe<ClientTypeModel>([ loadingId ],
            [ SignalEntityType.ClientType ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientTypeDelete(): Observable<ChangesContainer<ClientTypeModel>> {
        return this.subscribe<ClientTypeModel>([],
            [ SignalEntityType.ClientType ], [ SignalEntityEvent.Removed ]);
    }


    public onClientAdd(): Observable<ChangesContainer<ClientModel>> {
        return this.subscribe<ClientModel>([],
            [ SignalEntityType.Client], [ SignalEntityEvent.Created ]);
    }

    public onClientUpdate(): Observable<ChangesContainer<ClientModel>> {
        return this.subscribe<ClientModel>([],
            [ SignalEntityType.Client ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientUpdateById(loadingId: EntityId): Observable<ChangesContainer<ClientModel>> {
        return this.subscribe<ClientModel>([ loadingId ],
            [ SignalEntityType.Client ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientDelete(): Observable<ChangesContainer<ClientModel>> {
        return this.subscribe<ClientModel>([],
            [ SignalEntityType.Client ], [ SignalEntityEvent.Removed ]);
    }

    public onCarrierAdd(): Observable<ChangesContainer<CarrierModel>> {
        return this.subscribe<CarrierModel>([],
            [ SignalEntityType.Carrier], [ SignalEntityEvent.Created ]);
    }

    public onCarrierUpdate(): Observable<ChangesContainer<CarrierModel>> {
        return this.subscribe<CarrierModel>([],
            [ SignalEntityType.Carrier ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarrierUpdateById(loadingId: EntityId): Observable<ChangesContainer<CarrierModel>> {
        return this.subscribe<CarrierModel>([ loadingId ],
            [ SignalEntityType.Carrier ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarrierDelete(): Observable<ChangesContainer<CarrierModel>> {
        return this.subscribe<CarrierModel>([],
            [ SignalEntityType.Carrier ], [ SignalEntityEvent.Removed ]);
    }

    public onCarTractorAdd(): Observable<ChangesContainer<CarTractorModel>> {
        return this.subscribe<CarTractorModel>([],
            [ SignalEntityType.CarTractor], [ SignalEntityEvent.Created ]);
    }

    public onCarTractorUpdate(): Observable<ChangesContainer<CarTractorModel>> {
        return this.subscribe<CarTractorModel>([],
            [ SignalEntityType.CarTractor ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarTractorUpdateById(loadingId: EntityId): Observable<ChangesContainer<CarTractorModel>> {
        return this.subscribe<CarTractorModel>([ loadingId ],
            [ SignalEntityType.CarTractor ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarTractorDelete(): Observable<ChangesContainer<CarTractorModel>> {
        return this.subscribe<CarTractorModel>([],
            [ SignalEntityType.CarTractor ], [ SignalEntityEvent.Removed ]);
    }



    public onCarAdd(): Observable<ChangesContainer<CarModel>> {
        return this.subscribe<CarModel>([],
            [ SignalEntityType.Car], [ SignalEntityEvent.Created ]);
    }

    public onCarUpdate(): Observable<ChangesContainer<CarModel>> {
        return this.subscribe<CarModel>([],
            [ SignalEntityType.Car ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarUpdateById(loadingId: EntityId): Observable<ChangesContainer<CarModel>> {
        return this.subscribe<CarModel>([ loadingId ],
            [ SignalEntityType.Car ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarDelete(): Observable<ChangesContainer<CarModel>> {
        return this.subscribe<CarModel>([],
            [ SignalEntityType.Car ], [ SignalEntityEvent.Removed ]);
    }


    public onCarTrailerAdd(): Observable<ChangesContainer<CarTrailerModel>> {
        return this.subscribe<CarTrailerModel>([],
            [ SignalEntityType.CarTrailer], [ SignalEntityEvent.Created ]);
    }

    public onCarTrailerUpdate(): Observable<ChangesContainer<CarTrailerModel>> {
        return this.subscribe<CarTrailerModel>([],
            [ SignalEntityType.CarTrailer ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarTrailerUpdateById(loadingId: EntityId): Observable<ChangesContainer<CarTrailerModel>> {
        return this.subscribe<CarTrailerModel>([ loadingId ],
            [ SignalEntityType.CarTrailer ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarTrailerDelete(): Observable<ChangesContainer<CarTrailerModel>> {
        return this.subscribe<CarTrailerModel>([],
            [ SignalEntityType.CarTrailer ], [ SignalEntityEvent.Removed ]);
    }

    public onSupplierAdd(): Observable<ChangesContainer<SupplierModel>> {
        return this.subscribe<SupplierModel>([],
            [ SignalEntityType.Supplier], [ SignalEntityEvent.Created ]);
    }

    public onSupplierUpdate(): Observable<ChangesContainer<SupplierModel>> {
        return this.subscribe<SupplierModel>([],
            [ SignalEntityType.Supplier ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onSupplierUpdateById(loadingId: EntityId): Observable<ChangesContainer<SupplierModel>> {
        return this.subscribe<SupplierModel>([ loadingId ],
            [ SignalEntityType.Supplier ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onSupplierDelete(): Observable<ChangesContainer<SupplierModel>> {
        return this.subscribe<SupplierModel>([],
            [ SignalEntityType.Supplier ], [ SignalEntityEvent.Removed ]);
    }

    public onProductRuleAdd(): Observable<ChangesContainer<ProductRuleModel>> {
        return this.subscribe<ProductRuleModel>([],
            [ SignalEntityType.ProductRule], [ SignalEntityEvent.Created ]);
    }

    public onProductRuleUpdate(): Observable<ChangesContainer<ProductRuleModel>> {
        return this.subscribe<ProductRuleModel>([],
            [ SignalEntityType.ProductRule ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onProductRuleUpdateById(loadingId: EntityId): Observable<ChangesContainer<ProductRuleModel>> {
        return this.subscribe<ProductRuleModel>([ loadingId ],
            [ SignalEntityType.ProductRule ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onProductRuleDelete(): Observable<ChangesContainer<ProductRuleModel>> {
        return this.subscribe<ProductRuleModel>([],
            [ SignalEntityType.ProductRule ], [ SignalEntityEvent.Removed ]);
    }
    public onLegalEntityAdd(): Observable<ChangesContainer<LegalEntityModel>> {
        return this.subscribe<LegalEntityModel>([],
            [ SignalEntityType.LegalEntity], [ SignalEntityEvent.Created ]);
    }

    public onLegalEntityLinkToOwnAdd(): Observable<ChangesContainer<LegalEntityModel>> {
        return this.subscribe<LegalEntityModel>([],
            [ SignalEntityType.LegalEntityLinkToOwn], [ SignalEntityEvent.Created ]);
    }

    public onLegalEntityUpdate(): Observable<ChangesContainer<LegalEntityModel>> {
        return this.subscribe<LegalEntityModel>([],
            [ SignalEntityType.LegalEntity ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onLegalEntityUpdateById(loadingId: EntityId): Observable<ChangesContainer<LegalEntityModel>> {
        return this.subscribe<LegalEntityModel>([ loadingId ],
            [ SignalEntityType.LegalEntity ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onLegalEntityDelete(): Observable<ChangesContainer<LegalEntityModel>> {
        return this.subscribe<LegalEntityModel>([],
            [ SignalEntityType.LegalEntity ], [ SignalEntityEvent.Removed ]);
    }


    public onSettlementAccountAdd(): Observable<ChangesContainer<SettlementAccountModel>> {
        return this.subscribe<SettlementAccountModel>([],
            [ SignalEntityType.SettlementAccount], [ SignalEntityEvent.Created ]);
    }

    public onSettlementAccountUpdate(): Observable<ChangesContainer<SettlementAccountModel>> {
        return this.subscribe<SettlementAccountModel>([],
            [ SignalEntityType.SettlementAccount ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onSettlementAccountUpdateById(loadingId: EntityId): Observable<ChangesContainer<SettlementAccountModel>> {
        return this.subscribe<SettlementAccountModel>([ loadingId ],
            [ SignalEntityType.SettlementAccount ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onSettlementAccountDelete(): Observable<ChangesContainer<SettlementAccountModel>> {
        return this.subscribe<SettlementAccountModel>([],
            [ SignalEntityType.SettlementAccount ], [ SignalEntityEvent.Removed ]);
    }
    public onClientAddressAdd(): Observable<ChangesContainer<ClientAddressModel>> {
        return this.subscribe<ClientAddressModel>([],
            [ SignalEntityType.ClientAddress], [ SignalEntityEvent.Created ]);
    }

    public onClientAddressUpdate(): Observable<ChangesContainer<ClientAddressModel>> {
        return this.subscribe<ClientAddressModel>([],
            [ SignalEntityType.ClientAddress ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientAddressUpdateById(loadingId: EntityId): Observable<ChangesContainer<ClientAddressModel>> {
        return this.subscribe<ClientAddressModel>([ loadingId ],
            [ SignalEntityType.ClientAddress ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onClientAddressDelete(): Observable<ChangesContainer<ClientAddressModel>> {
        return this.subscribe<ClientAddressModel>([],
            [ SignalEntityType.ClientAddress ], [ SignalEntityEvent.Removed ]);
    }

    public onBasisAdd(): Observable<ChangesContainer<BasisModel>> {
        return this.subscribe<BasisModel>([],
            [ SignalEntityType.Basis], [ SignalEntityEvent.Created ]);
    }

    public onBasisUpdate(): Observable<ChangesContainer<BasisModel>> {
        return this.subscribe<BasisModel>([],
            [ SignalEntityType.Basis ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onBasisUpdateById(loadingId: EntityId): Observable<ChangesContainer<BasisModel>> {
        return this.subscribe<BasisModel>([ loadingId ],
            [ SignalEntityType.Basis ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onBasisDelete(): Observable<ChangesContainer<BasisModel>> {
        return this.subscribe<BasisModel>([],
            [ SignalEntityType.Basis ], [ SignalEntityEvent.Removed ]);
    }

    public onBasisGroupAdd(): Observable<ChangesContainer<BasisGroupModel>> {
        return this.subscribe<BasisGroupModel>([],
            [ SignalEntityType.BasisGroup], [ SignalEntityEvent.Created ]);
    }

    public onBasisGroupUpdate(): Observable<ChangesContainer<BasisGroupModel>> {
        return this.subscribe<BasisGroupModel>([],
            [ SignalEntityType.BasisGroup ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onBasisGroupUpdateById(loadingId: EntityId): Observable<ChangesContainer<BasisGroupModel>> {
        return this.subscribe<BasisGroupModel>([ loadingId ],
            [ SignalEntityType.BasisGroup ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onBasisGroupDelete(): Observable<ChangesContainer<BasisGroupModel>> {
        return this.subscribe<BasisGroupModel>([],
            [ SignalEntityType.BasisGroup ], [ SignalEntityEvent.Removed ]);
    }

    public onDriverAdd(): Observable<ChangesContainer<DriverModel>> {
        return this.subscribe<DriverModel>([],
            [ SignalEntityType.Driver], [ SignalEntityEvent.Created ]);
    }

    public onDriverUpdate(): Observable<ChangesContainer<DriverModel>> {
        return this.subscribe<DriverModel>([],
            [ SignalEntityType.Driver ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onDriverUpdateById(loadingId: EntityId): Observable<ChangesContainer<DriverModel>> {
        return this.subscribe<DriverModel>([ loadingId ],
            [ SignalEntityType.Driver ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onDriverDelete(): Observable<ChangesContainer<DriverModel>> {
        return this.subscribe<DriverModel>([],
            [ SignalEntityType.Driver ], [ SignalEntityEvent.Removed ]);
    }

    public onCarAxleAdd(): Observable<ChangesContainer<CarAxleModel>> {
        return this.subscribe<CarAxleModel>([],
            [ SignalEntityType.CarAxle], [ SignalEntityEvent.Created ]);
    }

    public onCarAxleUpdate(): Observable<ChangesContainer<CarAxleModel>> {
        return this.subscribe<CarAxleModel>([],
            [ SignalEntityType.CarAxle ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarAxleUpdateById(loadingId: EntityId): Observable<ChangesContainer<CarAxleModel>> {
        return this.subscribe<CarAxleModel>([ loadingId ],
            [ SignalEntityType.CarAxle ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCarAxleDelete(): Observable<ChangesContainer<CarAxleModel>> {
        return this.subscribe<CarAxleModel>([],
            [ SignalEntityType.CarAxle ], [ SignalEntityEvent.Removed ]);
    }
    public onTrailerSectionAdd(): Observable<ChangesContainer<TrailerSectionModel>> {
        return this.subscribe<TrailerSectionModel>([],
            [ SignalEntityType.CarTrailerSection], [ SignalEntityEvent.Created ]);
    }

    public onTrailerSectionUpdate(): Observable<ChangesContainer<TrailerSectionModel>> {
        return this.subscribe<TrailerSectionModel>([],
            [ SignalEntityType.CarTrailerSection ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onCTrailerSectionUpdateById(loadingId: EntityId): Observable<ChangesContainer<TrailerSectionModel>> {
        return this.subscribe<TrailerSectionModel>([ loadingId ],
            [ SignalEntityType.CarTrailerSection ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onTrailerSectionDelete(): Observable<ChangesContainer<TrailerSectionModel>> {
        return this.subscribe<TrailerSectionModel>([],
            [ SignalEntityType.CarTrailerSection ], [ SignalEntityEvent.Removed ]);
    }

    public onTankAdd(): Observable<ChangesContainer<TankModel>> {
        return this.subscribe<TankModel>([],
            [ SignalEntityType.Tank], [ SignalEntityEvent.Created ]);
    }

    public onDepotAdd(): Observable<ChangesContainer<DepotModel>> {
        return this.subscribe<DepotModel>([],
            [ SignalEntityType.Depot], [ SignalEntityEvent.Created ]);
    }

    public onDepotUpdate(): Observable<ChangesContainer<DepotModel>> {
        return this.subscribe<DepotModel>([],
            [ SignalEntityType.Depot ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onDepotUpdateById(loadingId: EntityId): Observable<ChangesContainer<DepotModel>> {
        return this.subscribe<DepotModel>([ loadingId ],
            [ SignalEntityType.Depot ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onDepotDelete(): Observable<ChangesContainer<DepotModel>> {
        return this.subscribe<DepotModel>([],
            [ SignalEntityType.Depot ], [ SignalEntityEvent.Removed ]);
    }

    public onTankUpdate(): Observable<ChangesContainer<TankModel>> {
        return this.subscribe<TankModel>([],
            [ SignalEntityType.Tank ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onTankUpdateById(loadingId: EntityId): Observable<ChangesContainer<TankModel>> {
        return this.subscribe<TankModel>([ loadingId ],
            [ SignalEntityType.Tank ], [ SignalEntityEvent.Updated, SignalEntityEvent.Archived ]);
    }

    public onTankDelete(): Observable<ChangesContainer<TankModel>> {
        return this.subscribe<TankModel>([],
            [ SignalEntityType.Tank ], [ SignalEntityEvent.Removed ]);
    }

    private subscribe<T>(ids: EntityId[], types: SignalEntityType[], events: SignalEntityEvent[]): Observable<ChangesContainer<T>> {
        return this.pipeline.pipe(
            filter(container => {
                if (0 != ids.length && !ids.includes(container.message.id)) {
                    return false;
                }

                if (0 != types.length && !types.includes(container.message.type)) {
                    return false;
                }

                if (0 != events.length && !events.includes(container.message.event)) {
                    return false;
                }
                return true;
            })
        );
    }

    public destroy() {
        this.signalSubscription.unsubscribe();
    }
}

export interface ChangesContainer<T> {
    message: SignalEntityEventContainer;
    data: T;
}
