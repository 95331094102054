import { ExtraOptions, Routes } from '@angular/router';
import { AuthComponent, LogoutComponent, PermissionGuard, TokenComponent } from 'oil-auth';
import { HandbookPermissionKeysEnum } from "./models/types/handbook-permission-keys.enum";
const ɵ0 = { permission: HandbookPermissionKeysEnum.HANDBOOK }, ɵ1 = () => import("./pages/pages.module.ngfactory")
    .then(m => m.PagesModuleNgFactory);
const routes = [
    {
        path: '',
        canActivate: [PermissionGuard],
        data: ɵ0,
        loadChildren: ɵ1,
    },
    {
        path: 'authorization',
        component: AuthComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'token',
        component: TokenComponent,
    },
    { path: '**', redirectTo: '' },
];
const config = {
    useHash: false,
};
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
