export enum HandbookPermissionKeysEnum {
    AUTH = 'auth',
    AUTHAPIKEYS = 'auth:api_keys',
    HANDBOOK = 'handbook',
    EMPLOYEE = 'handbook:employees',
    EMPLOYEEPERMISSIONSDESCRIPTORS = 'handbook:employees:permissions:descriptors',
    EMPLOYEESACCESS = 'handbook:employees:access',
    PRODUCTS = 'handbook:products',
    CLIENTS = 'handbook:clients',
    CARRIERS = 'handbook:carriers',
    SUPPLIERS = 'handbook:suppliers',
    LEGALENTITIES = 'handbook:legal_entities',
    DEPOT = 'depot',
    HANDBOOKDEPOT = 'handbook:depots',
    LOGISTICS = 'logistics',
    PRICES = 'prices',
    BASES = 'handbook:bases',
    CLIENTSBASES = 'handbook:clients:bases',
    OWNLEGALENTITIES = 'handbook:legal_entities:own',
    ACCOUNTS = 'handbook:legal_entities:accounts',
    EMPLOYEESPERMISSIONS = 'handbook:employees:permissions',
    CLIENTSTYPES = 'handbook:clients:types',
    CLIENTSLEGALENTITIES = 'handbook:clients:legal_entities',
    CLIENTSADDRESSES = 'handbook:clients:addresses',
    CATALOGNOMENCLATURESTYPES = 'handbook:catalog:nomenclatures:types',
    CATALOGNOMENCLATURES = 'handbook:catalog:nomenclatures',
    SUPPLIERSLEGALENTITIES = 'handbook:suppliers:legal_entities',
    CATALOGPRODUCTS = 'handbook:catalog:products',
    CATALOG = 'handbook:catalog',
    CARRIERSTRAILERSSECTIONS = 'handbook:carriers:cars:trailers:sections',
    CARRIERSTRAILERS = 'handbook:carriers:cars:trailers',
    CARRIERSTRACTORS = 'handbook:carriers:cars:tractors',
    CARRIERSCARSAXLES = 'handbook:carriers:cars:axles',
    CARRIERSCARS = 'handbook:carriers:cars',
    CARRIERSDRIVERS = 'handbook:carriers:drivers',
    CARDRIVERS = 'handbook:carriers:cars:drivers',
    CARRIERSLEGALENTITIES = 'handbook:carriers:legal_entities',
    CATALOGGROUPS = 'handbook:catalog:groups',
    BASESGROUPS = 'handbook:bases:groups',
    SUPPLIERSPRODUCTRULES = 'handbook:suppliers:product_rules',
}
