import { Injectable } from "@angular/core"
import * as signalR from "@microsoft/signalr"
import { Observable, Subject } from "rxjs"
import { environment } from "../../environments/environment"
import {EntityId} from "oil-handbook-api/lib/api.interface";

@Injectable({
	providedIn: "root"
})
export class SignalRService {

	private pipeline = new Subject<SignalEntityEventContainer>()

	public constructor() {
		this.buildConnection()
	}

	public get onReceive(): Observable<SignalEntityEventContainer> {
		return this.pipeline
	}

	private buildConnection() {
		const hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(`${environment.baseUrl}/hubs/notifications`)
			.withAutomaticReconnect([ 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000 ])
			.build()

		this.restart(hubConnection)

		hubConnection.onclose(() => {
			this.restart(hubConnection)
		})
	}

	private restart(connection: signalR.HubConnection) {
		connection.start().then(() => {
			connection.on("Entity", (data: SignalEntityEventContainer) => {
				this.pipeline.next(data)
			})
		})
	}
}

export interface SignalEntityEventContainer {
	id: EntityId
	event: SignalEntityEvent
	type: SignalEntityType
}

export enum SignalEntityEvent {
	Created = "Created",
	Updated = "Updated",
	Removed = "Removed",
	Reorder = "Reorder",
	Archived = "Archived"
}

export enum SignalEntityType {
	Employee = "Employee",
	CatalogGroup = "CatalogGroup",
	Product = "Product",
	ClientType = "ClientType",
	Client = "Client",
	Carrier = "Carrier",
	Supplier = "Supplier",
	ProductRule = "ProductRule",
	LegalEntity = "LegalEntity",
	SettlementAccount = "SettlementAccount",
	ClientAddress = "ClientAddress",
	Basis = "Basis",
	BasisGroup = "BasisGroup",
	Driver = "Driver",
	CarSection = "CarSection",
	CarAxle = "CarAxle",
	CarTractor = "CarTractor",
	CarTrailerSection = "CarTrailerSection",
	Car = "Car",
	CarTrailer = "CarTrailer",
	LegalEntityLinkToOwn = "LegalEntityLinkToOwn",
	Depot = "Depot",
	Tank = "Tank",
}
